var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-iam", attrs: { fluid: "", "pa-0": "" } },
    [
      _c(_setup.ProjectLinksGrid, {
        attrs: {
          projectId: _setup.props.projectId,
          preFilter: _setup.preFilter,
          addLink: _setup.addLink,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }